<template>
  <div>
    <button
      class="btn btn-outline-blue mb-3"
      @click="addPhone"
      v-if="$can({ key: 'providers', expectedPermission: 'edit' })"
    >
      <icon type="plus" /> Add Phone
    </button>
    <alert class="my-4" v-if="phones.isLoading" />
    <alert
      class="my-4"
      v-if="!phones.isLoading && phones.data.length == 0"
      :hideLoader="true"
      >No Phones</alert
    >
    <div
      class="card mobile-tablet-no-card table-container border-0"
      v-if="!phones.isLoading && phones.data.length > 0"
    >
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Number</th>
            <th>Status</th>
            <th>Type</th>
            <th
              v-if="$can({ key: 'providers', expectedPermission: 'edit' })"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="phone in phones.data" :key="phone.id">
            <td>
              <b class="mobile-tablet-only mr-2">Number:</b>{{ phone.number }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">status:</b
              ><span
                :class="
                  phone.status == 'ACTIVE' ? 'text-success' : 'text-danger'
                "
                >{{ phone.status }}</span
              >
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Type:</b>{{ phone.type }}
            </td>
            <td
              v-if="$can({ key: 'providers', expectedPermission: 'edit' })"
              class="text-right"
              :class="`${phones.data.length > 1 ? 'td-actions' : ''}`"
            >
              <button
                v-if="phones.data.length > 1"
                class="btn btn-danger btn-sm"
                @click="deletePhone(phone)"
                :disabled="phone.isDeleting"
              >
                <b class="mobile-tablet-only mr-2">Delete</b>
                <icon type="trash" v-if="!phone.isDeleting" />
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="phone.isDeleting"
                ></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "ProviderPhone",
  computed: {
    ...mapState(["providers"]),
    ...mapState("providers", ["phones"]),
  },
  mounted() {
    this.get(this.providers.provider.user_id);
  },
  methods: {
    ...mapActions({
      get: "providers/phones/get",
    }),
    addPhone: function () {
      this.$router.push({
        name: "providers.phones.create",
        params: { id: this.$route.params.id },
        query: { src: "phones" },
      });
    },
    deletePhone: function (phone) {
      phone.isDeleting = true;
      let vm = this;
      vm.$swal({
        title: "Are you sure",
        text: "Are you sure you want to delete this phone number?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          vm.$store.dispatch("providers/phones/remove", {
            user: vm.providers.provider.user_id,
            id: phone.id,
          });
        } else {
          phone.isDeleting = false;
        }
      });
    },
  },
};
</script>
